/* Help Page Styling */
.help-container {
  width: 100%;
  min-height: 100vh;
  color: #333;
  background-color: #f8f9fb;
}

/* Hero Section */
.help-hero {
  background: linear-gradient(135deg, #6e3afc, #5068f5);
  padding: 80px 20px;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.help-hero::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23ffffff' fill-opacity='0.1'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  z-index: 0;
}

.help-hero-content {
  position: relative;
  z-index: 1;
  max-width: 800px;
  margin: 0 auto;
}

.help-hero h1 {
  font-size: 3rem;
  font-weight: 700;
  color: white;
  margin-bottom: 20px;
}

.help-hero p {
  font-size: 1.2rem;
  color: rgba(255, 255, 255, 0.9);
  max-width: 600px;
  margin: 0 auto 40px;
}

.search-container {
  display: flex;
  max-width: 600px;
  margin: 0 auto;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
  border-radius: 50px;
  overflow: hidden;
}

.search-input {
  flex: 1;
  padding: 18px 25px;
  border: none;
  font-size: 1.1rem;
  color: #333;
}

.search-input:focus {
  outline: none;
}

.search-button {
  background: white;
  border: none;
  padding: 0 25px;
  font-size: 1.2rem;
  color: #6e3afc;
  cursor: pointer;
  transition: all 0.3s ease;
}

.search-button:hover {
  color: #5068f5;
}

/* Help Content */
.help-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 60px 20px;
  display: grid;
  grid-template-columns: 250px 1fr;
  gap: 40px;
}

.faq-categories {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.category-button {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 15px 20px;
  background: transparent;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 500;
  color: #555;
  cursor: pointer;
  transition: all 0.3s ease;
  text-align: left;
}

.category-button i {
  width: 20px;
  text-align: center;
  font-size: 1.1rem;
}

.category-button:hover {
  background: #f0f0f5;
  color: #6e3afc;
}

.category-button.active {
  background: linear-gradient(45deg, #6e3afc, #5068f5);
  color: white;
  font-weight: 600;
  box-shadow: 0 4px 15px rgba(110, 58, 252, 0.2);
}

.faq-section {
  background: white;
  border-radius: 15px;
  padding: 30px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  margin-bottom: 3rem;
}

.search-results-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 25px;
}

.search-results-header h2 {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 5px;
  color: #222;
}

.search-results-header p {
  color: #666;
  margin-bottom: 15px;
}

.clear-search {
  background: #f0f0f5;
  border: none;
  padding: 8px 15px;
  border-radius: 20px;
  font-size: 0.9rem;
  color: #555;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  transition: all 0.3s ease;
}

.clear-search:hover {
  background: #e5e5f0;
  color: #6e3afc;
}

.faq-category-title {
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 1.5rem;
  font-weight: 700;
  color: #222;
  margin-bottom: 25px;
}

.faq-category-title i {
  font-size: 1.3rem;
  color: #6e3afc;
}

.faq-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.faq-item {
  background: #fff;
  border-radius: 8px;
  margin-bottom: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.faq-item.expanded {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
}

.faq-question {
  padding: 1.2rem;
  background: #f8f9fa;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  color: #2c3e50;
  transition: background-color 0.3s ease;
}

.faq-question:hover {
  background: #e9ecef;
}

.faq-question.active {
  background: #e9ecef;
}

.faq-question h3 {
  font-size: 1.1rem;
  font-weight: 600;
  color: #222;
  margin: 0;
}

.faq-question i {
  color: #6e3afc;
  font-size: 0.9rem;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: rgba(110, 58, 252, 0.1);
  transition: all 0.3s ease;
}

.faq-item.active .faq-question i {
  transform: rotate(180deg);
}

.faq-answer {
  padding: 1.2rem;
  color: #333;
  line-height: 1.6;
  background-color: #fff;
  border-top: 1px solid #e0e0e0;
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}

.faq-answer.show {
  opacity: 1;
  max-height: 500px;
  overflow: visible;
}

.faq-item .icon {
  transition: transform 0.3s ease;
}

.faq-item.active .icon {
  transform: rotate(180deg);
}

.no-results {
  text-align: center;
  padding: 40px 20px;
  color: #666;
}

.no-results i {
  font-size: 3rem;
  color: #ddd;
  margin-bottom: 20px;
}

.no-results h3 {
  font-size: 1.3rem;
  font-weight: 600;
  margin-bottom: 10px;
  color: #222;
}

.no-results p {
  max-width: 450px;
  margin: 0 auto;
  line-height: 1.6;
}

/* Contact Section */
.help-contact {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px 60px;
}

.contact-card {
  background: white;
  padding: 30px;
  border-radius: 15px;
  text-align: center;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.contact-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
}

.contact-icon {
  width: 80px;
  height: 80px;
  background: linear-gradient(45deg, #6e3afc, #5068f5);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 20px;
  font-size: 32px;
  color: white;
}

.contact-card h3 {
  font-size: 1.3rem;
  font-weight: 600;
  margin-bottom: 15px;
  color: #222;
}

.contact-card p {
  color: #666;
  line-height: 1.6;
  margin-bottom: 20px;
}

.contact-button {
  display: inline-block;
  padding: 12px 25px;
  background: linear-gradient(45deg, #6e3afc, #5068f5);
  color: white;
  border: none;
  border-radius: 30px;
  font-size: 1rem;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

.contact-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 8px 15px rgba(110, 58, 252, 0.3);
}

/* Quick Links */
.quick-links {
  background: #f0f0f5;
  padding: 60px 20px;
  text-align: center;
}

.quick-links h2 {
  font-size: 2rem;
  font-weight: 700;
  color: #222;
  margin-bottom: 40px;
}

.links-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.quick-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  padding: 30px;
  background: white;
  border-radius: 15px;
  text-decoration: none;
  color: #333;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.quick-link:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  color: #6e3afc;
}

.quick-link i {
  font-size: 2rem;
  color: #6e3afc;
}

.quick-link span {
  font-size: 1.1rem;
  font-weight: 600;
}

/* Media Queries */
@media (max-width: 992px) {
  .help-content {
    grid-template-columns: 1fr;
  }
  
  .faq-categories {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .category-button {
    flex: 1;
    min-width: 130px;
    justify-content: center;
    padding: 12px 15px;
  }
  
  .category-button span {
    font-size: 0.9rem;
  }
}

@media (max-width: 768px) {
  .help-hero h1 {
    font-size: 2.5rem;
  }
  
  .help-hero p {
    font-size: 1.1rem;
  }
  
  .search-input {
    padding: 15px 20px;
  }
  
  .search-button {
    padding: 0 20px;
  }
  
  .help-content {
    padding: 40px 20px;
  }
  
  .faq-section {
    padding: 20px;
  }
  
  .category-button {
    font-size: 0.9rem;
  }
  
  .faq-question h3 {
    font-size: 1rem;
  }
}

@media (max-width: 576px) {
  .help-hero h1 {
    font-size: 2rem;
  }
  
  .help-hero p {
    font-size: 1rem;
    margin-bottom: 30px;
  }
  
  .search-input {
    font-size: 0.95rem;
  }
  
  .category-button {
    flex-direction: column;
    gap: 8px;
    padding: 10px;
  }
  
  .category-button i {
    font-size: 1.2rem;
  }
  
  .quick-links h2 {
    font-size: 1.8rem;
    margin-bottom: 30px;
  }
}

.help-header {
  text-align: center;
  margin-bottom: 3rem;
}

.help-header h1 {
  font-size: 2.5rem;
  color: #2c3e50;
  margin-bottom: 1rem;
}

.help-header p {
  font-size: 1.1rem;
  color: #666;
  line-height: 1.6;
}

.faq-section h2 {
  font-size: 1.8rem;
  color: #2c3e50;
  margin-bottom: 1.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid #e0e0e0;
}

.contact-section {
  background: #f8f9fa;
  padding: 2rem;
  border-radius: 8px;
  margin-top: 3rem;
}

.contact-section h2 {
  font-size: 1.8rem;
  color: #2c3e50;
  margin-bottom: 1.5rem;
}

.contact-section p {
  color: #666;
  line-height: 1.6;
  margin-bottom: 1.5rem;
}

.contact-section a {
  color: #3498db;
  text-decoration: none;
  transition: color 0.3s ease;
}

.contact-section a:hover {
  color: #2980b9;
} 