/* Modern Chatbot Styling */
.chatbot-container {
  position: fixed;
  z-index: 1000;
  transition: all 0.3s ease;
}

/* Chat button when closed */
.chatbot-button {
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: linear-gradient(135deg, #6e3afc, #5068f5);
  color: white;
  border: none;
  box-shadow: 0 5px 25px rgba(110, 58, 252, 0.3);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  transition: all 0.3s ease;
  animation: pulse 2s infinite;
}

.chatbot-button:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 25px rgba(110, 58, 252, 0.4);
}

/* Chat container when open */
.chatbot-container.open {
  width: 380px;
  height: 550px;
  position: fixed;
  bottom: 30px;
  right: 30px;
  border-radius: 20px;
  background: white;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  animation: slide-up 0.3s ease;
}

.chatbot-container.closed {
  width: auto;
  height: auto;
}

/* Chat header */
.chatbot-header {
  padding: 15px 20px;
  background: linear-gradient(135deg, #6e3afc, #5068f5);
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chatbot-title {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 18px;
}

.chatbot-avatar {
  background: rgba(255, 255, 255, 0.2);
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  font-size: 18px;
}

.chatbot-actions button {
  background: transparent;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 16px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}

.chatbot-actions button:hover {
  background: rgba(255, 255, 255, 0.2);
}

/* Chat messages area */
.chatbot-box {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  background: #f8f9fb;
  display: flex;
  flex-direction: column;
}

/* Welcome message */
.welcome-message {
  background: white;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 15px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

.welcome-message h3 {
  color: #333;
  margin-bottom: 10px;
  font-size: 18px;
}

.welcome-message p {
  color: #666;
  margin-bottom: 10px;
  font-size: 14px;
}

.welcome-message ul {
  padding-left: 20px;
}

.welcome-message li {
  color: #555;
  margin-bottom: 5px;
  font-size: 13px;
}

/* Chat message bubbles */
.chatbot-message {
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
  max-width: 85%;
}

.chatbot-message.bot {
  align-self: flex-start;
}

.chatbot-message.user {
  align-self: flex-end;
  flex-direction: row-reverse;
}

.message-avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  margin: 0 8px;
}

.message-avatar.user {
  background: #5068f5;
  color: white;
}

.chatbot-message.bot .message-avatar {
  background: #6e3afc;
  color: white;
}

.message-content {
  padding: 12px 15px;
  border-radius: 18px;
  font-size: 14px;
  line-height: 1.4;
}

.chatbot-message.bot .message-content {
  background: white;
  color: #333;
  border-top-left-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
}

.chatbot-message.user .message-content {
  background: linear-gradient(135deg, #6e3afc, #5068f5);
  color: white;
  border-top-right-radius: 4px;
  box-shadow: 0 2px 5px rgba(110, 58, 252, 0.2);
}

/* Typing indicator */
.typing-indicator {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  background: white;
  border-radius: 18px;
  width: 60px;
  justify-content: center;
}

.typing-indicator span {
  height: 8px;
  width: 8px;
  background: #6e3afc;
  border-radius: 50%;
  display: inline-block;
  margin: 0 2px;
  opacity: 0.4;
}

.typing-indicator span:nth-child(1) {
  animation: blink 1s infinite 0.2s;
}

.typing-indicator span:nth-child(2) {
  animation: blink 1s infinite 0.4s;
}

.typing-indicator span:nth-child(3) {
  animation: blink 1s infinite 0.6s;
}

/* Chat input area */
.chatbot-input {
  display: flex;
  padding: 15px;
  background: white;
  border-top: 1px solid #eee;
}

.chatbot-input input {
  flex: 1;
  padding: 12px 15px;
  border: 1px solid #e0e0e0;
  border-radius: 25px;
  outline: none;
  font-size: 14px;
  transition: all 0.2s ease;
}

.chatbot-input input:focus {
  border-color: #6e3afc;
  box-shadow: 0 0 0 2px rgba(110, 58, 252, 0.1);
}

.chatbot-input button {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: linear-gradient(135deg, #6e3afc, #5068f5);
  color: white;
  border: none;
  margin-left: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  transition: all 0.2s ease;
}

.chatbot-input button:hover {
  transform: scale(1.05);
  background: linear-gradient(135deg, #5c2df5, #4259e9);
}

.chatbot-footer {
  padding: 10px;
  background: #f8f9fb;
  border-top: 1px solid #eee;
  text-align: center;
  font-size: 12px;
  color: #888;
}

/* Animations */
@keyframes slide-up {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes blink {
  0%, 100% {
    opacity: 0.4;
  }
  50% {
    opacity: 1;
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(110, 58, 252, 0.4);
  }
  70% {
    box-shadow: 0 0 0 15px rgba(110, 58, 252, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(110, 58, 252, 0);
  }
}

/* Scrollbar styling */
.chatbot-box::-webkit-scrollbar {
  width: 6px;
}

.chatbot-box::-webkit-scrollbar-track {
  background: transparent;
}

.chatbot-box::-webkit-scrollbar-thumb {
  background: #d9d9d9;
  border-radius: 10px;
}

.chatbot-box::-webkit-scrollbar-thumb:hover {
  background: #c0c0c0;
}

.settings-btn {
  background: none;
  border: none;
  color: rgba(255, 255, 255, 0.7);
  margin-right: 10px;
  cursor: pointer;
  transition: color 0.2s ease;
}

.settings-btn:hover {
  color: #fff;
}

.api-key-notice {
  background-color: rgba(255, 165, 0, 0.1);
  border-left: 3px solid #ffa500;
  padding: 10px;
  margin-top: 15px;
  border-radius: 4px;
}

.api-key-notice p {
  color: #ff8c00;
  font-size: 0.9rem;
  margin: 0;
}
  