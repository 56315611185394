.auth-page-wrapper {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.8)),
              url('https://variety.com/wp-content/uploads/2024/01/100-Greatest-TV-Shows-V1-2.jpg') center/cover no-repeat;
  padding: 2rem 1rem;
  position: relative;
  overflow: hidden;
}

.auth-page-wrapper::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: 
    radial-gradient(circle at 20% 20%, rgba(100, 255, 218, 0.15) 0%, transparent 50%),
    radial-gradient(circle at 80% 80%, rgba(147, 112, 219, 0.15) 0%, transparent 50%);
  pointer-events: none;
}

.auth-container {
  background: rgba(23, 25, 35, 0.85);
  padding: 2.5rem;
  border-radius: 20px;
  box-shadow: 
    0 8px 32px 0 rgba(31, 38, 135, 0.37),
    0 0 0 1px rgba(100, 255, 218, 0.1),
    0 0 20px rgba(100, 255, 218, 0.1);
  backdrop-filter: blur(12px);
  width: 100%;
  max-width: 420px;
  color: white;
  overflow-y: auto;
  max-height: 90vh;
  position: relative;
  transition: all 0.3s ease;
  border: 1px solid rgba(100, 255, 218, 0.1);
}

.auth-container:hover {
  transform: translateY(-5px);
  box-shadow: 
    0 12px 40px 0 rgba(31, 38, 135, 0.45),
    0 0 0 1px rgba(100, 255, 218, 0.2),
    0 0 30px rgba(100, 255, 218, 0.2);
}

.brand-title {
  font-family: 'Orbitron', sans-serif;
  font-size: 3rem;
  font-weight: 700;
  color: #64ffda;
  text-align: center;
  margin-bottom: 1rem;
  letter-spacing: 4px;
  text-shadow: 
    0 0 10px rgba(100, 255, 218, 0.5),
    0 0 20px rgba(100, 255, 218, 0.3);
}

.welcome-title {
  font-family: 'Orbitron', sans-serif;
  font-size: 2rem;
  color: #fff;
  margin-bottom: 0.5rem;
  text-align: center;
}

.welcome-subtitle {
  color: #a8b2d1;
  margin-bottom: 2rem;
  text-align: center;
}

.form-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.form-group {
  margin-bottom: 1rem;
  position: relative;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: #64ffda;
  font-size: 0.9rem;
  font-weight: 500;
}

.form-control {
  width: 100%;
  padding: 12px;
  background-color: rgba(255, 255, 255, 0.2);
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 8px;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.form-control::placeholder {
  color: rgba(255, 255, 255, 1);
  opacity: 1;
  font-weight: 500;
  text-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.form-control:focus {
  background-color: rgba(255, 255, 255, 0.25);
  border-color: #64ffda;
  color: #fff;
  box-shadow: 
    0 0 0 2px rgba(100, 255, 218, 0.2),
    0 0 10px rgba(100, 255, 218, 0.1);
}

.form-control:focus::placeholder {
  opacity: 0.6;
}

select.form-control {
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 1em;
}

select.form-control option {
  background-color: #1a1b26;
  color: white;
}

input[type="date"].form-control {
  color-scheme: dark;
}

.btn {
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-bottom: 1rem;
}

.btn-primary {
  background: #64ffda;
  color: #0a192f;
}

.btn-primary:hover {
  background: #4ad8b7;
  transform: translateY(-2px);
}

.btn-google {
  background: #ffffff;
  color: #333333;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.btn-google:hover {
  background: #f5f5f5;
  transform: translateY(-2px);
}

.btn-google i {
  color: #ea4335;
  font-size: 1.2rem;
}

.toggle-auth {
  color: #64ffda;
  text-align: center;
  margin-top: 1rem;
  cursor: pointer;
  transition: color 0.3s ease;
}

.toggle-auth:hover {
  color: #4ad8b7;
}

.robot-icon {
  width: 150px;
  height: 150px;
  margin: 0 auto 1.5rem;
  display: block;
  object-fit: contain;
  filter: drop-shadow(0 0 8px rgba(100, 255, 218, 0.3));
}

.auth-footer {
  text-align: center;
  color: #8892b0;
  font-size: 0.8rem;
  margin-top: 2rem;
}

.alert {
  background: rgba(255, 99, 71, 0.2);
  border: 1px solid rgba(255, 99, 71, 0.3);
  color: #ff6347;
  padding: 12px;
  border-radius: 8px;
  margin-bottom: 1rem;
  text-align: center;
}

.footer {
  margin-top: auto;
  width: 100%;
  text-align: center;
  padding: 10px 0;
  background-color: #f8f9fa; /* İsteğe bağlı arka plan rengi */
  position: fixed;
  bottom: 0;
  left: 0;
}

@media (max-width: 480px) {
  .form-row {
    grid-template-columns: 1fr;
  }
  
  .auth-container {
    padding: 1.5rem;
  }
  
  .brand-title {
    font-size: 2.5rem;
  }
  
  .welcome-title {
    font-size: 1.5rem;
  }
}
  
  