/* Contact Page Styling */
.contact-container {
  width: 100%;
  min-height: 100vh;
  background-color: #f8f9fb;
  color: #333;
}

/* Hero Section */
.contact-hero {
  background: linear-gradient(to right, #6e3afc, #5068f5);
  padding: 80px 20px;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.contact-hero::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml,%3Csvg width='100' height='100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpattern id='pattern' patternUnits='userSpaceOnUse' width='20' height='20'%3E%3Ccircle cx='10' cy='10' r='2' fill='white' fill-opacity='0.2'/%3E%3C/pattern%3E%3Crect width='100' height='100' fill='url(%23pattern)'/%3E%3C/svg%3E");
  opacity: 0.1;
  z-index: 0;
}

.contact-hero-content {
  position: relative;
  z-index: 1;
  max-width: 800px;
  margin: 0 auto;
}

.contact-hero h1 {
  font-size: 3rem;
  font-weight: 700;
  color: white;
  margin-bottom: 20px;
}

.contact-hero p {
  font-size: 1.2rem;
  color: rgba(255, 255, 255, 0.9);
  max-width: 600px;
  margin: 0 auto;
}

/* Contact Content */
.contact-content {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  padding: 80px 50px;
  max-width: 1400px;
  margin: 0 auto;
}

/* Contact Info */
.contact-info {
  flex: 1;
  min-width: 300px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  gap: 20px;
}

.contact-card {
  background: white;
  border-radius: 10px;
  padding: 30px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.contact-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
}

.contact-icon {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: linear-gradient(45deg, #6e3afc, #5068f5);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.contact-icon i {
  font-size: 24px;
  color: white;
}

.contact-card h3 {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 15px;
  color: #222;
}

.contact-card p {
  font-size: 0.95rem;
  color: #666;
  margin-bottom: 5px;
}

/* Contact Form */
.contact-form-container {
  flex: 1;
  min-width: 300px;
  background: white;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
}

.contact-form-container h2 {
  font-size: 1.8rem;
  font-weight: 700;
  margin-bottom: 15px;
  color: #222;
}

.contact-form-container > p {
  font-size: 1rem;
  color: #666;
  margin-bottom: 30px;
}

.form-message {
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.form-message.success {
  background: rgba(46, 213, 115, 0.15);
  color: #2ed573;
}

.form-message.error {
  background: rgba(255, 71, 87, 0.15);
  color: #ff4757;
}

.form-row {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.form-group {
  flex: 1;
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  color: #333;
}

.required {
  color: #ff4757;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 12px 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.form-group input:focus,
.form-group textarea:focus {
  border-color: #6e3afc;
  box-shadow: 0 0 0 3px rgba(110, 58, 252, 0.1);
  outline: none;
}

.submit-btn {
  background: linear-gradient(45deg, #6e3afc, #5068f5);
  color: white;
  border: none;
  padding: 14px 25px;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: 100%;
}

.submit-btn:hover {
  transform: translateY(-3px);
  box-shadow: 0 8px 20px rgba(110, 58, 252, 0.3);
}

/* Map Section */
.contact-map {
  padding: 0 50px 80px;
  max-width: 1400px;
  margin: 0 auto;
}

.contact-map h2 {
  text-align: center;
  font-size: 1.8rem;
  font-weight: 700;
  margin-bottom: 30px;
  color: #222;
}

.map-container {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
}

/* FAQ Section */
.contact-faq {
  background: #0c0c14;
  padding: 80px 50px;
  color: white;
}

.contact-faq h2 {
  text-align: center;
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 40px;
}

.faq-container {
  max-width: 1000px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 30px;
}

.faq-item {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  padding: 25px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.faq-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
}

.faq-item h3 {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 15px;
  color: #6e3afc;
}

.faq-item p {
  font-size: 0.95rem;
  color: rgba(255, 255, 255, 0.7);
  line-height: 1.6;
}

/* Media Queries */
@media (max-width: 768px) {
  .contact-hero {
    padding: 60px 20px;
  }
  
  .contact-hero h1 {
    font-size: 2.5rem;
  }
  
  .contact-content {
    padding: 50px 20px;
  }
  
  .form-row {
    flex-direction: column;
    gap: 0;
  }
  
  .contact-map {
    padding: 0 20px 50px;
  }
  
  .contact-faq {
    padding: 50px 20px;
  }
  
  .faq-container {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 480px) {
  .contact-hero h1 {
    font-size: 2rem;
  }
  
  .contact-hero p {
    font-size: 1rem;
  }
  
  .contact-info {
    grid-template-columns: 1fr;
  }
} 