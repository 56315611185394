.preferences-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
}

.preferences-progress {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  gap: 1rem;
}

.progress-step {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: #fff;
  transition: all 0.3s ease;
}

.progress-step.active {
  background: #64ffda;
  color: #0a192f;
}

.preferences-step {
  text-align: center;
}

.preferences-step h2 {
  color: #64ffda;
  margin-bottom: 1rem;
  font-size: 2rem;
}

.preferences-step p {
  color: #8892b0;
  margin-bottom: 2rem;
}

.genres-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 1rem;
  margin-bottom: 2rem;
}

.genre-button {
  padding: 1rem;
  border: 2px solid #64ffda;
  background: transparent;
  color: #64ffda;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 1rem;
}

.genre-button:hover {
  background: rgba(100, 255, 218, 0.1);
}

.genre-button.selected {
  background: #64ffda;
  color: #0a192f;
}

.content-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 2rem;
  margin-bottom: 2rem;
}

.content-card {
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 10px;
  overflow: hidden;
}

.content-card img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 10px;
}

.content-card h3 {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.8));
  color: #fff;
  margin: 0;
  font-size: 1rem;
}

.content-card.selected::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(100, 255, 218, 0.3);
  border: 2px solid #64ffda;
  border-radius: 10px;
}

.step-buttons {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 2rem;
}

.next-button,
.back-button,
.submit-button {
  padding: 1rem 2rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.next-button,
.submit-button {
  background: #64ffda;
  color: #0a192f;
}

.back-button {
  background: transparent;
  border: 2px solid #64ffda;
  color: #64ffda;
}

.next-button:disabled,
.submit-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.accessibility-options {
  display: flex;
  justify-content: center;
  margin: 2rem 0;
}

.accessibility-option {
  display: flex;
  align-items: center;
  gap: 1rem;
  color: #8892b0;
  cursor: pointer;
}

.accessibility-option input[type="checkbox"] {
  width: 20px;
  height: 20px;
  accent-color: #64ffda;
}

.preferences-loading,
.preferences-error {
  text-align: center;
  padding: 2rem;
  color: #64ffda;
  font-size: 1.2rem;
}

@media (max-width: 768px) {
  .preferences-container {
    padding: 1rem;
  }

  .genres-grid {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  }

  .content-grid {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }

  .content-card img {
    height: 225px;
  }
} 