.register-page-wrapper {
  display: none;
}

.register-page {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, 
    rgba(10, 25, 47, 0.85),
    rgba(17, 34, 64, 0.8),
    rgba(44, 62, 80, 0.75)
  ),
  url('https://variety.com/wp-content/uploads/2024/01/100-Greatest-TV-Shows-V1-2.jpg') center/cover no-repeat fixed;
  padding: 2rem;
  position: relative;
  overflow: hidden;
}

.register-page::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: 
    radial-gradient(circle at 20% 20%, rgba(100, 255, 218, 0.2) 0%, transparent 50%),
    radial-gradient(circle at 80% 80%, rgba(147, 112, 219, 0.2) 0%, transparent 50%);
  pointer-events: none;
  z-index: 0;
}

.register-container {
  width: 100%;
  max-width: 1000px;
  background: rgba(16, 33, 65, 0.9);
  backdrop-filter: blur(12px);
  border-radius: 20px;
  padding: 2.5rem;
  box-shadow: 
    0 8px 32px rgba(0, 0, 0, 0.4),
    0 0 0 1px rgba(100, 255, 218, 0.1),
    0 0 20px rgba(100, 255, 218, 0.1);
  border: 1px solid rgba(100, 255, 218, 0.1);
  transition: all 0.3s ease;
  position: relative;
  z-index: 1;
}

.register-container:hover {
  transform: translateY(-5px);
  box-shadow: 
    0 12px 40px rgba(0, 0, 0, 0.5),
    0 0 0 1px rgba(100, 255, 218, 0.2),
    0 0 30px rgba(100, 255, 218, 0.2);
  border-color: rgba(100, 255, 218, 0.2);
}

.register-header {
  text-align: center;
  margin-bottom: 2rem;
}

.brand-title {
  font-size: 2.5rem;
  color: #64ffda;
  margin-bottom: 1.5rem;
  font-weight: bold;
  letter-spacing: 2px;
}

.steps-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem 0;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.step-number {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba(100, 255, 218, 0.1);
  border: 2px solid #64ffda;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #64ffda;
  font-weight: bold;
  transition: all 0.3s ease;
}

.step.active .step-number {
  background: #64ffda;
  color: #0a192f;
}

.step-label {
  margin-top: 0.5rem;
  color: #8892b0;
  font-size: 0.9rem;
}

.step.active .step-label {
  color: #64ffda;
}

.step-line {
  width: 100px;
  height: 2px;
  background: rgba(100, 255, 218, 0.3);
  margin: 0 1rem;
}

.register-form {
  padding: 1rem;
}

.form-subtitle {
  color: #8892b0;
  text-align: center;
  margin-bottom: 2rem;
}

.form-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.form-group {
  margin-bottom: 1rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: #ccd6f6;
  font-size: 0.9rem;
}

.form-control {
  width: 100%;
  padding: 0.75rem 1rem;
  border-radius: 8px;
  border: 2px solid rgba(100, 255, 218, 0.1);
  background: rgba(255, 255, 255, 0.05);
  color: #fff;
  font-size: 1rem;
  transition: all 0.3s ease;
  font-family: inherit;
}

.form-control:focus {
  outline: none;
  border-color: #64ffda;
  background: rgba(100, 255, 218, 0.1);
}

.form-control::placeholder {
  color: rgba(255, 255, 255, 0.5);
  opacity: 1;
}

/* Input type email ve password için özel stiller */
input[type="email"],
input[type="password"] {
  font-family: inherit;
  background: rgba(255, 255, 255, 0.05);
  color: #fff;
}

input[type="email"]::placeholder,
input[type="password"]::placeholder {
  font-family: inherit;
  color: rgba(255, 255, 255, 0.5);
  opacity: 1;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: 0 0 0px 1000px rgba(255, 255, 255, 0.05) inset;
  transition: background-color 5000s ease-in-out 0s;
}

.form-control option {
  background: #0a192f;
  color: #fff;
}

.error-message {
  background: rgba(255, 0, 0, 0.1);
  color: #ff6b6b;
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 1rem;
  text-align: center;
}

.form-actions {
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin-top: 2rem;
}

.btn-continue,
.btn-back {
  padding: 0.75rem 2rem;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.btn-continue {
  background: #64ffda;
  color: #0a192f;
  border: none;
}

.btn-continue:hover {
  background: #4cd7b7;
  transform: translateY(-2px);
}

.btn-continue:disabled {
  background: rgba(100, 255, 218, 0.5);
  cursor: not-allowed;
  transform: none;
}

.btn-back {
  background: transparent;
  color: #64ffda;
  border: 2px solid #64ffda;
}

.btn-back:hover {
  background: rgba(100, 255, 218, 0.1);
}

.preferences-section {
  padding: 1rem;
}

@media (max-width: 768px) {
  .register-container {
    padding: 1rem;
  }

  .form-grid {
    grid-template-columns: 1fr;
  }

  .step-line {
    width: 50px;
  }

  .form-actions {
    flex-direction: column;
  }

  .btn-continue,
  .btn-back {
    width: 100%;
  }
} 