/* Home Page Styling */
.home-container {
  width: 100%;
  min-height: 100vh;
  color: #333;
  background-color: #f8f9fb;
}

/* Hero Section */
.hero-section {
  position: relative;
  height: 80vh;
  min-height: 600px;
  max-height: 800px;
  overflow: hidden;
  margin-bottom: 80px;
}

.hero-slider {
  height: 100%;
  position: relative;
}

.hero-slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 1s ease;
  z-index: 1;
}

.hero-slide.active {
  opacity: 1;
  z-index: 2;
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  z-index: 1;
}

.hero-content {
  position: absolute;
  bottom: 15%;
  left: 10%;
  max-width: 600px;
  color: white;
  z-index: 2;
  animation: fadeIn 1s ease forwards;
}

.hero-content h1 {
  font-size: 3.5rem;
  font-weight: 700;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  margin-bottom: 20px;
}

.hero-content p {
  font-size: 1.2rem;
  margin-bottom: 30px;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  line-height: 1.5;
}

.hero-button {
  background: linear-gradient(135deg, #64ffda, #4ad8b7);
  color: #0a192f;
  border: none;
  padding: 1rem 2rem;
  border-radius: 8px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 15px rgba(100, 255, 218, 0.3);
  text-transform: uppercase;
  letter-spacing: 1px;
}

.hero-button:hover {
  background: linear-gradient(135deg, #4ad8b7, #3bc4a6);
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(100, 255, 218, 0.4);
}

.slider-controls {
  position: absolute;
  bottom: 5%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.slider-dots {
  display: flex;
  gap: 8px;
}

.slider-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.5);
  cursor: pointer;
  transition: all 0.3s ease;
}

.slider-dot.active {
  background: white;
  transform: scale(1.2);
}

.prev-slide, .next-slide {
  background: rgba(0, 0, 0, 0.3);
  color: white;
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  margin: 0 15px;
}

.prev-slide:hover, .next-slide:hover {
  background: rgba(0, 0, 0, 0.6);
}

/* Features Section */
.features-section {
  padding: 80px 5%;
  background: white;
  margin-bottom: 80px;
  opacity: 0;
  transform: translateY(50px);
  transition: all 1s ease;
}

.features-section.visible {
  opacity: 1;
  transform: translateY(0);
}

.section-header {
  text-align: center;
  margin-bottom: 60px;
}

.section-header h2 {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 15px;
  color: #222;
}

.section-header p {
  font-size: 1.2rem;
  color: #666;
  max-width: 600px;
  margin: 0 auto;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
  max-width: 1200px;
  margin: 0 auto;
}

.feature-card {
  background: #f8f9fb;
  padding: 30px;
  border-radius: 15px;
  text-align: center;
  transition: all 0.3s ease;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
}

.feature-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
}

.feature-icon {
  width: 70px;
  height: 70px;
  background: linear-gradient(45deg, #6e3afc, #5068f5);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 20px;
  font-size: 28px;
  color: white;
}

.feature-card h3 {
  font-size: 1.3rem;
  font-weight: 600;
  margin-bottom: 15px;
  color: #222;
}

.feature-card p {
  color: #666;
  line-height: 1.6;
}

/* Content Sections */
.content-section {
  padding: 80px 5%;
  background: #f8f9fb;
  margin-bottom: 80px;
  opacity: 0;
  transform: translateY(50px);
  transition: all 1s ease;
}

.content-section.visible {
  opacity: 1;
  transform: translateY(0);
}

.content-slider {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  gap: 25px;
  max-width: 1400px;
  margin: 0 auto;
}

.content-card {
  background: white;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.content-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
}

.content-image {
  position: relative;
  overflow: hidden;
  aspect-ratio: 2/3;
}

.content-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.content-card:hover .content-image img {
  transform: scale(1.05);
}

.content-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.content-card:hover .content-overlay {
  opacity: 1;
}

.play-button {
  width: 60px;
  height: 60px;
  background: linear-gradient(45deg, #6e3afc, #5068f5);
  border: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 20px;
  cursor: pointer;
  margin-bottom: 15px;
  transform: scale(0.8);
  opacity: 0;
  transition: all 0.3s ease 0.1s;
}

.content-card:hover .play-button {
  transform: scale(1);
  opacity: 1;
}

.content-actions {
  display: flex;
  gap: 15px;
  transform: translateY(20px);
  opacity: 0;
  transition: all 0.3s ease 0.2s;
}

.content-card:hover .content-actions {
  transform: translateY(0);
  opacity: 1;
}

.action-button {
  width: 40px;
  height: 40px;
  background: rgba(255, 255, 255, 0.2);
  border: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.action-button:hover {
  background: rgba(255, 255, 255, 0.4);
}

.content-info {
  padding: 15px;
}

.content-info h3 {
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 10px;
  color: #222;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.content-details {
  display: flex;
  justify-content: space-between;
  color: #666;
  font-size: 0.9rem;
}

.content-rating {
  display: flex;
  align-items: center;
  gap: 5px;
}

.content-rating i {
  color: #ffc107;
}

.see-more {
  text-align: center;
  margin-top: 40px;
}

.see-more a {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  color: #6e3afc;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.3s ease;
}

.see-more a:hover {
  gap: 12px;
}

/* Devices Section */
.devices-section {
  padding: 80px 5%;
  background: white;
  margin-bottom: 80px;
  opacity: 0;
  transform: translateY(50px);
  transition: all 1s ease;
}

.devices-section.visible {
  opacity: 1;
  transform: translateY(0);
}

.devices-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.devices-info {
  padding-right: 20px;
}

.devices-info h2 {
  font-size: 2.2rem;
  font-weight: 700;
  margin-bottom: 20px;
  color: #222;
}

.devices-info p {
  font-size: 1.1rem;
  color: #666;
  margin-bottom: 30px;
  line-height: 1.6;
}

.devices-list {
  list-style: none;
  padding: 0;
  margin-bottom: 30px;
}

.devices-list li {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 15px;
  font-size: 1.1rem;
  color: #555;
}

.devices-list li i {
  width: 40px;
  height: 40px;
  background: linear-gradient(45deg, #6e3afc, #5068f5);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 18px;
}

.devices-link {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  color: #6e3afc;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.3s ease;
}

.devices-link:hover {
  gap: 12px;
}

.devices-image {
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
}

.devices-image img {
  width: 100%;
  height: auto;
  display: block;
}

/* CTA Section */
.cta-section {
  background: linear-gradient(45deg, #6e3afc, #5068f5);
  padding: 80px 5%;
  color: white;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.cta-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23ffffff' fill-opacity='0.1'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  z-index: 0;
}

.cta-content {
  position: relative;
  z-index: 1;
  max-width: 700px;
  margin: 0 auto;
}

.cta-content h2 {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 15px;
}

.cta-content p {
  font-size: 1.2rem;
  opacity: 0.9;
  margin-bottom: 30px;
}

.cta-button {
  display: inline-block;
  padding: 15px 40px;
  background: white;
  color: #6e3afc;
  border: none;
  border-radius: 30px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  text-decoration: none;
}

.cta-button:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.3);
}

/* Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Media Queries */
@media (max-width: 1200px) {
  .hero-content h1 {
    font-size: 3rem;
  }
  
  .section-header h2 {
    font-size: 2.2rem;
  }
}

@media (max-width: 992px) {
  .hero-section {
    height: 70vh;
    min-height: 500px;
  }
  
  .hero-content {
    left: 5%;
    max-width: 500px;
  }
  
  .hero-content h1 {
    font-size: 2.5rem;
  }
  
  .devices-content {
    grid-template-columns: 1fr;
    text-align: center;
  }
  
  .devices-info {
    padding-right: 0;
    order: 2;
  }
  
  .devices-image {
    order: 1;
    max-width: 500px;
    margin: 0 auto;
  }
  
  .devices-list li {
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .hero-section {
    height: 60vh;
    min-height: 400px;
    margin-bottom: 60px;
  }
  
  .hero-content {
    bottom: 20%;
    max-width: 90%;
  }
  
  .hero-content h1 {
    font-size: 2.2rem;
    margin-bottom: 15px;
  }
  
  .hero-content p {
    font-size: 1rem;
    margin-bottom: 20px;
  }
  
  .hero-button {
    padding: 10px 24px;
    font-size: 1rem;
  }
  
  .section-header {
    margin-bottom: 40px;
  }
  
  .section-header h2 {
    font-size: 2rem;
  }
  
  .section-header p {
    font-size: 1rem;
  }
  
  .features-section, 
  .content-section, 
  .devices-section {
    padding: 60px 5%;
    margin-bottom: 60px;
  }
  
  .cta-section {
    padding: 60px 5%;
  }
  
  .cta-content h2 {
    font-size: 2rem;
  }
  
  .cta-content p {
    font-size: 1rem;
  }
  
  .cta-button {
    padding: 12px 30px;
    font-size: 1rem;
  }
}

@media (max-width: 576px) {
  .hero-section {
    height: 50vh;
    min-height: 350px;
    margin-bottom: 40px;
  }
  
  .hero-content h1 {
    font-size: 1.8rem;
  }
  
  .prev-slide, .next-slide {
    width: 32px;
    height: 32px;
    margin: 0 10px;
  }
  
  .content-slider {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .feature-icon {
    width: 60px;
    height: 60px;
    font-size: 24px;
  }
} 