/* Header Styles */
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  background-color: var(--header-bg);
  box-shadow: var(--header-shadow);
  z-index: 100;
  transition: all 0.3s ease;
}

.header.scrolled {
  height: 70px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding: 0 30px;
  max-width: 1400px;
  margin: 0 auto;
}

.logo {
  display: flex;
  align-items: center;
}

.logo a {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.logo-text {
  font-size: 1.8rem;
  font-weight: 700;
  background: var(--gradient-primary);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}

.nav-menu {
  display: flex;
}

.nav-menu ul {
  display: flex;
  list-style: none;
  gap: 30px;
}

.nav-menu li a {
  font-size: 1rem;
  font-weight: 500;
  color: var(--text-primary);
  text-decoration: none;
  padding: 8px 0;
  position: relative;
  transition: all 0.3s ease;
}

.nav-menu li a:hover {
  color: var(--accent-primary);
}

.nav-menu li.active a {
  color: var(--accent-primary);
}

.nav-menu li.active a::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background: var(--gradient-primary);
  border-radius: 3px;
}

.header-actions {
  display: flex;
  align-items: center;
  gap: 15px;
}

.theme-toggle {
  background: none;
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  color: var(--text-primary);
  cursor: pointer;
  transition: all 0.3s ease;
}

.theme-toggle:hover {
  background-color: var(--bg-tertiary);
  color: var(--accent-primary);
}

.user-menu {
  display: flex;
  align-items: center;
  gap: 10px;
}

.profile-link {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--gradient-primary);
  color: white;
  font-size: 1.2rem;
  transition: all 0.3s ease;
}

.profile-link:hover {
  box-shadow: 0 5px 15px rgba(110, 58, 252, 0.3);
  transform: translateY(-2px);
}

.sign-out-btn {
  background: none;
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  color: var(--text-primary);
  cursor: pointer;
  transition: all 0.3s ease;
}

.sign-out-btn:hover {
  background-color: var(--bg-tertiary);
  color: var(--error-color);
}

.mobile-menu-toggle {
  display: none;
  background: none;
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  color: var(--text-primary);
  cursor: pointer;
  transition: all 0.3s ease;
}

.mobile-menu-toggle:hover {
  background-color: var(--bg-tertiary);
}

/* Mobile Navigation */
@media (max-width: 768px) {
  .nav-menu {
    position: fixed;
    top: 70px;
    left: 0;
    width: 100%;
    height: 0;
    background-color: var(--bg-secondary);
    overflow: hidden;
    transition: height 0.3s ease;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    z-index: 99;
  }

  .nav-menu.open {
    height: auto;
  }

  .nav-menu ul {
    flex-direction: column;
    padding: 20px;
    gap: 15px;
  }

  .nav-menu li a {
    display: block;
    padding: 10px 0;
  }

  .header-actions {
    gap: 10px;
  }

  .mobile-menu-toggle {
    display: flex;
  }
}

/* Logo */
.logo-container {
  display: flex;
  align-items: center;
}

.logo-image {
  height: 36px;
  width: auto;
}

/* Desktop Navigation */
.desktop-nav {
  display: flex;
}

/* User Actions */
.user-actions {
  display: flex;
  align-items: center;
  gap: 20px;
}

.search-button {
  width: 38px;
  height: 38px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease;
}

.search-button:hover {
  background: rgba(255, 255, 255, 0.2);
}

.profile-dropdown {
  position: relative;
}

.profile-button {
  display: flex;
  align-items: center;
  gap: 10px;
  background: rgba(255, 255, 255, 0.1);
  padding: 5px 15px 5px 5px;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.profile-button:hover {
  background: rgba(255, 255, 255, 0.2);
}

.profile-image {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
}

.profile-avatar {
  width: 32px;
  height: 32px;
  background: linear-gradient(45deg, #6e3afc, #5068f5);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 16px;
}

.profile-name {
  color: white;
  font-weight: 500;
  font-size: 0.95rem;
  max-width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropdown-menu {
  position: absolute;
  top: calc(100% + 10px);
  right: 0;
  background: #1a1a2e;
  border-radius: 10px;
  min-width: 220px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
  padding: 10px 0;
  animation: fadeIn 0.3s ease forwards;
  z-index: 1000;
}

.dropdown-item {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 15px;
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.95rem;
  text-decoration: none;
  transition: all 0.3s ease;
  background: transparent;
  border: none;
  width: 100%;
  text-align: left;
  cursor: pointer;
}

.dropdown-item:hover {
  background: rgba(255, 255, 255, 0.1);
  color: white;
}

.dropdown-item i {
  width: 18px;
  text-align: center;
  font-size: 1.1rem;
  color: #6e3afc;
}

.dropdown-divider {
  height: 1px;
  background: rgba(255, 255, 255, 0.1);
  margin: 8px 0;
}

.sign-out {
  color: #ff6b6b;
}

.sign-out i {
  color: #ff6b6b;
}

/* Mobile Navigation Toggle */
.mobile-nav-toggle {
  display: none;
  cursor: pointer;
}

.menu-icon {
  width: 30px;
  height: 24px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.menu-icon span {
  display: block;
  height: 2px;
  width: 100%;
  background: white;
  transition: all 0.3s ease;
}

.menu-icon.open span:nth-child(1) {
  transform: translateY(11px) rotate(45deg);
}

.menu-icon.open span:nth-child(2) {
  opacity: 0;
}

.menu-icon.open span:nth-child(3) {
  transform: translateY(-11px) rotate(-45deg);
}

/* Mobile Navigation Menu */
.mobile-nav {
  position: fixed;
  top: 70px;
  left: 0;
  width: 100%;
  background: #1a1a2e;
  height: 0;
  overflow: hidden;
  transition: height 0.3s ease;
  z-index: 999;
}

.mobile-nav.open {
  height: calc(100vh - 70px);
}

.mobile-nav-links {
  list-style: none;
  padding: 20px;
  margin: 0;
}

.mobile-nav-links li {
  margin: 15px 0;
}

.mobile-nav-links li a {
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
  font-size: 1.1rem;
  font-weight: 500;
  display: block;
  padding: 10px 0;
  transition: all 0.3s ease;
}

.mobile-nav-links li a:hover,
.mobile-nav-links li a.active {
  color: white;
}

.mobile-nav-links li a.active {
  font-weight: 600;
}

.mobile-nav-links li a i {
  width: 24px;
  text-align: center;
  margin-right: 10px;
  color: #6e3afc;
}

.mobile-divider {
  height: 1px;
  background: rgba(255, 255, 255, 0.1);
  margin: 20px 0;
}

.mobile-sign-out {
  display: flex;
  align-items: center;
  gap: 10px;
  background: transparent;
  border: none;
  color: #ff6b6b;
  font-size: 1.1rem;
  font-weight: 500;
  padding: 10px 0;
  cursor: pointer;
  width: 100%;
  text-align: left;
}

.mobile-sign-out i {
  width: 24px;
  text-align: center;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Media Queries */
@media (max-width: 992px) {
  .desktop-nav {
    display: none;
  }

  .mobile-nav-toggle {
    display: block;
  }

  .profile-name {
    display: none;
  }

  .profile-button {
    padding: 5px;
  }
}

@media (max-width: 576px) {
  .logo-text {
    font-size: 1.3rem;
  }

  .logo-image {
    height: 30px;
  }

  .user-actions {
    gap: 15px;
  }

  .search-button,
  .profile-avatar,
  .profile-image {
    width: 32px;
    height: 32px;
    font-size: 14px;
  }
} 