.recommendations-container {
  padding: 2rem;
  min-height: 100vh;
  background: linear-gradient(135deg, 
    rgba(10, 25, 47, 0.85),
    rgba(17, 34, 64, 0.8),
    rgba(44, 62, 80, 0.75)
  ),
  url('https://variety.com/wp-content/uploads/2024/01/100-Greatest-TV-Shows-V1-2.jpg') center/cover no-repeat fixed;
}

.recommendations-container h2 {
  color: #64ffda;
  text-align: center;
  margin-bottom: 2rem;
  font-size: 2.5rem;
  font-weight: 600;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.recommendations-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  padding: 1rem;
}

.recommendation-card {
  background: rgba(16, 33, 65, 0.9);
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 
    0 8px 32px rgba(0, 0, 0, 0.4),
    0 0 0 1px rgba(100, 255, 218, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  backdrop-filter: blur(12px);
}

.recommendation-card:hover {
  transform: translateY(-5px);
  box-shadow: 
    0 12px 40px rgba(0, 0, 0, 0.5),
    0 0 0 1px rgba(100, 255, 218, 0.2);
}

.recommendation-card img {
  width: 100%;
  height: 400px;
  object-fit: cover;
  border-bottom: 1px solid rgba(100, 255, 218, 0.1);
}

.recommendation-info {
  padding: 1.5rem;
}

.recommendation-info h3 {
  color: #fff;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.recommendation-info p {
  color: #8892b0;
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 1.5rem;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.feedback-buttons {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.like-button,
.dislike-button {
  flex: 1;
  padding: 0.8rem;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.like-button {
  background: rgba(100, 255, 218, 0.1);
  color: #64ffda;
  border: 1px solid #64ffda;
}

.like-button:hover,
.like-button.active {
  background: #64ffda;
  color: #0a192f;
}

.dislike-button {
  background: rgba(255, 99, 71, 0.1);
  color: #ff6347;
  border: 1px solid #ff6347;
}

.dislike-button:hover,
.dislike-button.active {
  background: #ff6347;
  color: #fff;
}

.loading {
  color: #64ffda;
  text-align: center;
  padding: 2rem;
  font-size: 1.5rem;
}

.error {
  color: #ff6347;
  text-align: center;
  padding: 2rem;
  font-size: 1.2rem;
  background: rgba(255, 99, 71, 0.1);
  border-radius: 8px;
  margin: 2rem;
}

@media (max-width: 768px) {
  .recommendations-container {
    padding: 1rem;
  }

  .recommendations-grid {
    grid-template-columns: 1fr;
  }

  .recommendation-card img {
    height: 300px;
  }
} 