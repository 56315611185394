/* Service Page Styling */
.service-container {
  width: 100%;
  min-height: 100vh;
  color: #333;
  background-color: #f8f9fb;
}

/* Hero Section */
.service-hero {
  background: linear-gradient(135deg, #6e3afc, #5068f5);
  padding: 80px 20px;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.service-hero::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23ffffff' fill-opacity='0.1'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  z-index: 0;
}

.service-hero-content {
  position: relative;
  z-index: 1;
  max-width: 800px;
  margin: 0 auto;
}

.service-hero h1 {
  font-size: 3rem;
  font-weight: 700;
  color: white;
  margin-bottom: 20px;
}

.service-hero p {
  font-size: 1.2rem;
  color: rgba(255, 255, 255, 0.9);
  max-width: 600px;
  margin: 0 auto;
}

/* Tab Navigation */
.service-tabs {
  display: flex;
  justify-content: center;
  gap: 15px;
  padding: 20px;
  background: white;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  position: sticky;
  top: 0;
  z-index: 100;
}

.tab-button {
  padding: 12px 25px;
  background: transparent;
  border: none;
  border-radius: 30px;
  font-size: 1rem;
  font-weight: 500;
  color: #555;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 8px;
}

.tab-button i {
  font-size: 0.9rem;
}

.tab-button.active {
  background: linear-gradient(45deg, #6e3afc, #5068f5);
  color: white;
  box-shadow: 0 4px 15px rgba(110, 58, 252, 0.3);
}

.tab-button:hover:not(.active) {
  background: #f0f0f5;
}

/* Service Content */
.service-content {
  padding: 60px 50px;
  max-width: 1400px;
  margin: 0 auto;
}

.section-desc {
  text-align: center;
  font-size: 1.1rem;
  color: #666;
  max-width: 800px;
  margin: 0 auto 50px;
}

h2 {
  text-align: center;
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 20px;
  color: #222;
}

/* Plans Section */
.plans-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  margin-bottom: 50px;
}

.plan-card {
  background: white;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  border: 1px solid #eee;
}

.plan-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
}

.plan-tag {
  position: absolute;
  top: 15px;
  right: 15px;
  background: #FF6B6B;
  color: white;
  padding: 5px 12px;
  border-radius: 20px;
  font-size: 0.8rem;
  font-weight: 600;
}

.plan-header {
  background: #f8f9fb;
  padding: 30px;
  text-align: center;
}

.plan-header h3 {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 10px;
  color: #222;
}

.plan-price {
  display: flex;
  align-items: baseline;
  justify-content: center;
  gap: 2px;
}

.currency {
  font-size: 1.5rem;
  color: #555;
}

.amount {
  font-size: 3rem;
  font-weight: 700;
  color: #222;
  line-height: 1;
}

.period {
  font-size: 0.9rem;
  color: #888;
}

.plan-features {
  padding: 30px;
  list-style: none;
}

.plan-features li {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  gap: 10px;
  color: #555;
}

.plan-features li i {
  width: 20px;
  text-align: center;
}

.plan-features li i.fa-check {
  color: #2ed573;
}

.plan-features li i.fa-times {
  color: #ff4757;
}

.plan-button {
  width: calc(100% - 60px);
  margin: 0 30px 30px;
  padding: 15px;
  border: none;
  border-radius: 8px;
  background: linear-gradient(45deg, #6e3afc, #5068f5);
  color: white;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.plan-button:hover {
  box-shadow: 0 8px 15px rgba(110, 58, 252, 0.3);
}

.plan-card.standard {
  border-color: #6e3afc;
  transform: scale(1.05);
  z-index: 1;
}

.plan-card.standard .plan-header {
  background: linear-gradient(45deg, #6e3afc, #5068f5);
}

.plan-card.standard .plan-header h3,
.plan-card.standard .plan-price .currency,
.plan-card.standard .plan-price .amount,
.plan-card.standard .plan-price .period {
  color: white;
}

.plan-info {
  background: #f0f0f5;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}

.plan-info p {
  font-weight: 600;
  margin-bottom: 10px;
  color: #555;
}

.plan-info ul {
  display: flex;
  justify-content: center;
  gap: 30px;
  list-style: none;
  flex-wrap: wrap;
}

.plan-info ul li {
  position: relative;
  padding-left: 20px;
  color: #666;
}

.plan-info ul li::before {
  content: '✓';
  position: absolute;
  left: 0;
  color: #2ed573;
}

/* Features Section */
.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 30px;
}

.feature-card {
  background: white;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  text-align: center;
}

.feature-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
}

.feature-icon {
  width: 80px;
  height: 80px;
  background: linear-gradient(45deg, #6e3afc, #5068f5);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 20px;
}

.feature-icon i {
  font-size: 36px;
  color: white;
}

.feature-card h3 {
  font-size: 1.3rem;
  font-weight: 600;
  margin-bottom: 15px;
  color: #222;
}

.feature-card p {
  color: #666;
  line-height: 1.6;
}

/* Devices Section */
.devices-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 30px;
  margin-bottom: 30px;
}

.device-category {
  background: white;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.device-category:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
}

.device-icon {
  width: 70px;
  height: 70px;
  background: linear-gradient(45deg, #6e3afc, #5068f5);
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.device-icon i {
  font-size: 30px;
  color: white;
}

.device-category h3 {
  font-size: 1.3rem;
  font-weight: 600;
  margin-bottom: 15px;
  color: #222;
}

.device-category ul {
  padding-left: 20px;
  color: #666;
}

.device-category ul li {
  margin-bottom: 8px;
}

.devices-note {
  background: #f0f0f5;
  padding: 15px 20px;
  border-radius: 10px;
  text-align: center;
  color: #555;
  margin-top: 30px;
}

.devices-note i {
  color: #6e3afc;
  margin-right: 5px;
}

/* Testimonials Section */
.service-testimonials {
  background: linear-gradient(45deg, #0c0c14, #16213e);
  padding: 80px 50px;
  color: white;
}

.service-testimonials h2 {
  color: white;
  margin-bottom: 50px;
}

.testimonials-container {
  display: flex;
  gap: 30px;
  overflow-x: auto;
  padding: 20px 0;
  scrollbar-width: none; /* Firefox */
}

.testimonials-container::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}

.testimonial-card {
  min-width: 300px;
  flex: 1;
  background: rgba(255, 255, 255, 0.05);
  padding: 30px;
  border-radius: 15px;
  position: relative;
}

.testimonial-quote {
  font-size: 24px;
  color: #6e3afc;
  margin-bottom: 15px;
}

.testimonial-card p {
  color: rgba(255, 255, 255, 0.8);
  line-height: 1.6;
  margin-bottom: 20px;
}

.testimonial-author {
  display: flex;
  align-items: center;
  gap: 15px;
}

.author-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
}

.author-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.author-info h4 {
  margin: 0;
  font-size: 1rem;
  color: white;
}

.author-info span {
  font-size: 0.85rem;
  color: #6e3afc;
}

/* CTA Section */
.service-cta {
  background: linear-gradient(45deg, #6e3afc, #5068f5);
  padding: 80px 20px;
  text-align: center;
  color: white;
}

.cta-content {
  max-width: 700px;
  margin: 0 auto;
}

.cta-content h2 {
  color: white;
  font-size: 2.5rem;
  margin-bottom: 15px;
}

.cta-content p {
  font-size: 1.2rem;
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 30px;
}

.cta-button {
  padding: 15px 40px;
  background: white;
  color: #6e3afc;
  border: none;
  border-radius: 30px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.cta-button:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.3);
}

/* Media Queries */
@media (max-width: 768px) {
  .service-hero h1 {
    font-size: 2.5rem;
  }
  
  .service-content {
    padding: 40px 20px;
  }
  
  .service-tabs {
    flex-wrap: wrap;
  }
  
  .tab-button {
    width: 100%;
    justify-content: center;
  }
  
  .plan-card.standard {
    transform: scale(1);
  }
  
  .testimonials-container {
    flex-direction: column;
  }
  
  .testimonial-card {
    min-width: initial;
  }
  
  .service-testimonials, .service-cta {
    padding: 50px 20px;
  }
  
  .cta-content h2 {
    font-size: 2rem;
  }
}

@media (max-width: 480px) {
  .service-hero h1 {
    font-size: 2rem;
  }
  
  .service-hero p, .cta-content p {
    font-size: 1rem;
  }
  
  .features-grid, .devices-grid {
    grid-template-columns: 1fr;
  }
  
  .plan-info ul {
    flex-direction: column;
    gap: 10px;
  }
} 