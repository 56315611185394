.friends-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.friends-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.friends-header h2 {
  color: #333;
  font-size: 2rem;
  margin: 0;
}

.search-box {
  position: relative;
  width: 300px;
}

.search-box input {
  width: 100%;
  padding: 0.8rem 1rem;
  padding-right: 2.5rem;
  border: 2px solid #e0e0e0;
  border-radius: 25px;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.search-box input:focus {
  outline: none;
  border-color: #6e3afc;
  box-shadow: 0 0 0 3px rgba(110, 58, 252, 0.1);
}

.search-box i {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  color: #666;
}

.users-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1.5rem;
  margin-top: 1rem;
}

.user-card {
  background: white;
  border-radius: 12px;
  padding: 1rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.user-card:hover {
  transform: translateY(-5px);
}

.user-info {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.user-info img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 1rem;
  object-fit: cover;
}

.user-info h4 {
  margin: 0;
  color: #333;
  font-size: 1.1rem;
}

.user-info p {
  margin: 0.5rem 0 0;
  color: #666;
  font-size: 0.9rem;
}

.add-friend-btn {
  width: 100%;
  padding: 0.8rem;
  background: linear-gradient(135deg, #6e3afc, #5068f5);
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.add-friend-btn:hover {
  background: linear-gradient(135deg, #5c2df5, #4259e9);
  transform: translateY(-2px);
}

.error-message {
  background: #ffebee;
  color: #c62828;
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 1rem;
}

.loading {
  text-align: center;
  color: #666;
  font-size: 1.1rem;
  padding: 2rem;
}

.suggested-friends, .search-results {
  margin-top: 2rem;
}

.suggested-friends h3, .search-results h3 {
  color: #333;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

@media (max-width: 768px) {
  .friends-container {
    padding: 1rem;
  }

  .friends-header {
    flex-direction: column;
    gap: 1rem;
  }

  .search-box {
    width: 100%;
  }

  .users-grid {
    grid-template-columns: 1fr;
  }
} 