/* Global Theme Variables */
:root {
  /* Light Theme Colors (Default) */
  --bg-primary: #f8f9fb;
  --bg-secondary: #ffffff;
  --bg-tertiary: #f0f2f5;
  --text-primary: #333333;
  --text-secondary: #555555;
  --text-tertiary: #777777;
  --accent-primary: #6e3afc;
  --accent-secondary: #5068f5;
  --accent-tertiary: #3b82f6;
  --border-color: #e0e0e0;
  --card-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  --header-bg: rgba(255, 255, 255, 0.95);
  --header-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  --footer-bg: #f8f9fb;
  --error-color: #e53e3e;
  --success-color: #38a169;
  --warning-color: #f6ad55;
  --gradient-primary: linear-gradient(135deg, #6e3afc, #5068f5);
  --overlay-color: rgba(0, 0, 0, 0.5);
}

/* Dark Theme Colors */
.dark-theme {
  --bg-primary: #121212;
  --bg-secondary: #1e1e1e;
  --bg-tertiary: #2d2d2d;
  --text-primary: #f0f0f0;
  --text-secondary: #d0d0d0;
  --text-tertiary: #a0a0a0;
  --accent-primary: #8b5cf6;
  --accent-secondary: #7c3aed;
  --accent-tertiary: #6d28d9;
  --border-color: #333333;
  --card-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  --header-bg: rgba(30, 30, 30, 0.95);
  --header-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  --footer-bg: #1a1a1a;
  --error-color: #f56565;
  --success-color: #68d391;
  --warning-color: #fbd38d;
  --gradient-primary: linear-gradient(135deg, #8b5cf6, #7c3aed);
  --overlay-color: rgba(0, 0, 0, 0.7);
}

/* Global Reset & Base Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background-color: var(--bg-primary);
  color: var(--text-primary);
  line-height: 1.6;
  transition: background-color 0.3s ease, color 0.3s ease;
}

a {
  text-decoration: none;
  color: var(--accent-primary);
  transition: color 0.3s ease;
}

a:hover {
  color: var(--accent-secondary);
}

button {
  cursor: pointer;
  border: none;
  outline: none;
  font-family: inherit;
}

/* Main Layout */
.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex: 1;
  padding-top: 80px; /* Adjust based on header height */
}

/* Auth Layout */
.auth-layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: var(--bg-primary);
}

/* Loading Screen */
.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--bg-primary);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.spinner {
  width: 50px;
  height: 50px;
  border: 4px solid var(--border-color);
  border-top: 4px solid var(--accent-primary);
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 20px;
}

.loading-screen h2 {
  color: var(--text-primary);
  font-size: 1.5rem;
  font-weight: 600;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Button Styles */
.btn {
  padding: 10px 20px;
  border-radius: 8px;
  font-weight: 500;
  transition: all 0.3s ease;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.btn-primary {
  background: var(--gradient-primary);
  color: white;
}

.btn-primary:hover {
  box-shadow: 0 5px 15px rgba(110, 58, 252, 0.3);
  transform: translateY(-2px);
}

.btn-secondary {
  background-color: var(--bg-tertiary);
  color: var(--text-primary);
}

.btn-secondary:hover {
  background-color: var(--border-color);
}

.btn-outline {
  background-color: transparent;
  border: 1px solid var(--accent-primary);
  color: var(--accent-primary);
}

.btn-outline:hover {
  background-color: var(--accent-primary);
  color: white;
}

/* Form Styles */
.form-group {
  margin-bottom: 20px;
}

.form-label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  color: var(--text-primary);
}

.form-input {
  width: 100%;
  padding: 12px 15px;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  background-color: var(--bg-secondary);
  color: var(--text-primary);
  font-size: 1rem;
  transition: all 0.3s ease;
}

.form-input:focus {
  border-color: var(--accent-primary);
  box-shadow: 0 0 0 2px rgba(110, 58, 252, 0.1);
}

.form-error {
  color: var(--error-color);
  font-size: 0.875rem;
  margin-top: 5px;
}

/* Text Styles */
.text-sm { font-size: 0.875rem; }
.text-md { font-size: 1rem; }
.text-lg { font-size: 1.125rem; }
.text-xl { font-size: 1.25rem; }
.text-2xl { font-size: 1.5rem; }
.text-3xl { font-size: 1.875rem; }
.text-4xl { font-size: 2.25rem; }

.font-light { font-weight: 300; }
.font-normal { font-weight: 400; }
.font-medium { font-weight: 500; }
.font-semibold { font-weight: 600; }
.font-bold { font-weight: 700; }

.text-center { text-align: center; }
.text-left { text-align: left; }
.text-right { text-align: right; }

/* Utility Classes */
.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.mt-1 { margin-top: 0.25rem; }
.mt-2 { margin-top: 0.5rem; }
.mt-3 { margin-top: 0.75rem; }
.mt-4 { margin-top: 1rem; }
.mt-5 { margin-top: 1.25rem; }
.mt-6 { margin-top: 1.5rem; }
.mt-8 { margin-top: 2rem; }
.mt-10 { margin-top: 2.5rem; }

.mb-1 { margin-bottom: 0.25rem; }
.mb-2 { margin-bottom: 0.5rem; }
.mb-3 { margin-bottom: 0.75rem; }
.mb-4 { margin-bottom: 1rem; }
.mb-5 { margin-bottom: 1.25rem; }
.mb-6 { margin-bottom: 1.5rem; }
.mb-8 { margin-bottom: 2rem; }
.mb-10 { margin-bottom: 2.5rem; }

.mx-auto { margin-left: auto; margin-right: auto; }

.flex { display: flex; }
.flex-col { flex-direction: column; }
.items-center { align-items: center; }
.justify-center { justify-content: center; }
.justify-between { justify-content: space-between; }
.flex-wrap { flex-wrap: wrap; }
.gap-1 { gap: 0.25rem; }
.gap-2 { gap: 0.5rem; }
.gap-3 { gap: 0.75rem; }
.gap-4 { gap: 1rem; }

.w-full { width: 100%; }
.h-full { height: 100%; }

/* Custom scrollbar for modern browsers */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: var(--bg-tertiary);
}

::-webkit-scrollbar-thumb {
  background: var(--accent-tertiary);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--accent-secondary);
}

/* Media Queries */
@media (max-width: 1024px) {
  .container {
    max-width: 100%;
  }
}

@media (max-width: 768px) {
  .main-content {
    padding-top: 70px;
  }
  
  .container {
    padding: 0 15px;
  }
  
  .text-4xl {
    font-size: 1.875rem;
  }
  
  .text-3xl {
    font-size: 1.5rem;
  }
}

@media (max-width: 480px) {
  .container {
    padding: 0 10px;
  }
  
  .btn {
    padding: 8px 16px;
    font-size: 0.875rem;
  }
  
  .text-4xl {
    font-size: 1.5rem;
  }
  
  .text-3xl {
    font-size: 1.25rem;
  }
  
  .text-2xl {
    font-size: 1.125rem;
  }
}

.App {
    text-align: center;
    background-color: #f4f4f9;
    padding: 20px;
  }
  
  h1 {
    color: #333;
  }
  
  .movie-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
    margin-top: 20px;
  }
  
  .movie-card {
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .movie-card:hover {
    transform: scale(1.05);
  }
  
  .movie-poster {
    width: 100%;
    height: auto;
    border-bottom: 5px solid #333;
  }
  
  .movie-card h2 {
    font-size: 18px;
    color: #333;
    margin: 10px 0;
  }
  
  .movie-card p {
    font-size: 14px;
    color: #666;
    padding: 0 10px;
  }
  html, body {
    height: 100%;
    margin: 0;
    padding: 0;
  }
  
  .auth-page-wrapper {
    min-height: 100vh; /* Sayfa en az ekran yüksekliği kadar olsun */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .footer {
    margin-top: auto;
    width: 100%;
    text-align: center;
    padding: 10px 0;
    background-color: #f8f9fa; /* İsteğe bağlı arka plan rengi */
    position: fixed;
    bottom: 0;
    left: 0;
  }
  