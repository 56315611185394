.profile-container {
  min-height: 100vh;
  padding: 2rem;
  background: linear-gradient(135deg, 
    rgba(10, 25, 47, 0.85),
    rgba(17, 34, 64, 0.8),
    rgba(44, 62, 80, 0.75)
  ),
  url('https://variety.com/wp-content/uploads/2024/01/100-Greatest-TV-Shows-V1-2.jpg') center/cover no-repeat fixed;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.profile-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: 
    radial-gradient(circle at 20% 20%, rgba(100, 255, 218, 0.2) 0%, transparent 50%),
    radial-gradient(circle at 80% 80%, rgba(147, 112, 219, 0.2) 0%, transparent 50%);
  pointer-events: none;
  z-index: 0;
}

.profile-card {
  background: rgba(16, 33, 65, 0.9);
  backdrop-filter: blur(12px);
  border-radius: 20px;
  padding: 2.5rem;
  width: 100%;
  max-width: 700px;
  box-shadow: 
    0 8px 32px rgba(0, 0, 0, 0.4),
    0 0 0 1px rgba(100, 255, 218, 0.1),
    0 0 20px rgba(100, 255, 218, 0.1);
  border: 1px solid rgba(100, 255, 218, 0.1);
  position: relative;
  z-index: 2;
  transition: all 0.3s ease;
  margin-top: 2rem;
}

.profile-card:hover {
  transform: translateY(-5px);
  box-shadow: 
    0 12px 40px rgba(0, 0, 0, 0.5),
    0 0 0 1px rgba(100, 255, 218, 0.2),
    0 0 30px rgba(100, 255, 218, 0.2);
  border-color: rgba(100, 255, 218, 0.2);
}

.profile-card h2 {
  color: #fff;
  margin-bottom: 2rem;
  text-align: center;
  font-size: 2.5rem;
  font-weight: 600;
  background: linear-gradient(45deg, #64ffda, #00b4d8);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 1.8rem;
  position: relative;
  z-index: 3;
}

.form-group label {
  display: block;
  color: #fff;
  margin-bottom: 0.8rem;
  font-size: 1rem;
  font-weight: 500;
  transition: color 0.3s ease;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 1rem;
  border: 2px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.05);
  color: #fff;
  font-size: 1rem;
  transition: all 0.3s ease;
  position: relative;
  z-index: 4;
}

.form-group input:focus,
.form-group select:focus {
  outline: none;
  border-color: #64ffda;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 0 3px rgba(100, 255, 218, 0.1);
}

.form-group input:disabled,
.form-group select:disabled {
  background: rgba(255, 255, 255, 0.03);
  cursor: not-allowed;
  opacity: 0.7;
}

.form-group input[type="text"] {
  background: rgba(255, 255, 255, 0.05);
  border: 2px solid rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease;
}

.form-group input[type="text"]:focus {
  background: rgba(255, 255, 255, 0.1);
  border-color: #64ffda;
  box-shadow: 0 0 0 3px rgba(100, 255, 218, 0.1);
}

.form-group input[type="text"]::placeholder {
  color: rgba(255, 255, 255, 0.4);
}

.form-group input[type="text"]:disabled {
  background: rgba(255, 255, 255, 0.03);
  cursor: not-allowed;
  opacity: 0.7;
}

.button-group {
  display: flex;
  gap: 1.5rem;
  margin-top: 2.5rem;
  position: relative;
  z-index: 4;
}

.edit-button,
.save-button,
.cancel-button {
  padding: 1rem 2rem;
  border: none;
  border-radius: 12px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  flex: 1;
  position: relative;
  z-index: 5;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.edit-button {
  background: linear-gradient(45deg, #64ffda, #00b4d8);
  color: #1a1a1a;
}

.save-button {
  background: linear-gradient(45deg, #00b4d8, #64ffda);
  color: #1a1a1a;
}

.cancel-button {
  background: linear-gradient(45deg, #ff4d4d, #ff8080);
  color: #fff;
}

.edit-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(100, 255, 218, 0.3);
}

.save-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0, 180, 216, 0.3);
}

.cancel-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(255, 77, 77, 0.3);
}

.edit-button:disabled,
.save-button:disabled,
.cancel-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

.error-message {
  background: rgba(255, 77, 77, 0.1);
  color: #ff4d4d;
  padding: 1.2rem;
  border-radius: 12px;
  margin-bottom: 1.5rem;
  border: 2px solid rgba(255, 77, 77, 0.2);
  position: relative;
  z-index: 4;
  animation: slideIn 0.3s ease;
}

.success-message {
  background: rgba(100, 255, 218, 0.1);
  color: #64ffda;
  padding: 1.2rem;
  border-radius: 12px;
  margin-bottom: 1.5rem;
  border: 2px solid rgba(100, 255, 218, 0.2);
  position: relative;
  z-index: 4;
  animation: slideIn 0.3s ease;
}

.loading {
  color: #fff;
  text-align: center;
  padding: 2rem;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.loading::after {
  content: '';
  width: 20px;
  height: 20px;
  border: 2px solid #64ffda;
  border-radius: 50%;
  border-top-color: transparent;
  animation: spin 1s linear infinite;
}

@keyframes slideIn {
  from {
    transform: translateY(-10px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.text-muted {
  color: rgba(255, 255, 255, 0.6);
  font-size: 0.8rem;
  margin-top: 0.5rem;
  display: block;
}

@media (max-width: 768px) {
  .profile-container {
    padding: 1rem;
  }

  .profile-card {
    padding: 1.5rem;
  }

  .profile-card h2 {
    font-size: 2rem;
  }

  .button-group {
    flex-direction: column;
    gap: 1rem;
  }

  .edit-button,
  .save-button,
  .cancel-button {
    width: 100%;
    padding: 0.8rem 1.5rem;
  }
} 