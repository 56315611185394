/* Footer Styles */
.site-footer {
  background-color: #0c0c14;
  color: rgba(255, 255, 255, 0.8);
  padding: 80px 0 40px;
}

.footer-container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 20px;
}

/* Footer Top Section */
.footer-top {
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 60px;
  margin-bottom: 60px;
}

.footer-logo-image {
  height: 50px;
  width: auto;
  margin-bottom: 15px;
}

.footer-logo-text {
  font-size: 1.8rem;
  font-weight: 700;
  color: white;
  margin: 0 0 10px;
}

.footer-slogan {
  font-size: 1rem;
  margin-bottom: 25px;
  opacity: 0.8;
}

.social-links {
  display: flex;
  gap: 12px;
}

.social-link {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: linear-gradient(45deg, #6e3afc, #5068f5);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-decoration: none;
  font-size: 1.1rem;
  transition: all 0.3s ease;
}

.social-link:hover {
  transform: translateY(-3px);
  box-shadow: 0 8px 15px rgba(110, 58, 252, 0.3);
}

.footer-links {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
}

.footer-links-column h3 {
  color: white;
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 20px;
  position: relative;
  padding-bottom: 10px;
}

.footer-links-column h3::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 30px;
  height: 2px;
  background: linear-gradient(90deg, #6e3afc, #5068f5);
}

.footer-links-column ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-links-column ul li {
  margin-bottom: 12px;
}

.footer-links-column ul li a {
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
  font-size: 0.95rem;
  transition: all 0.3s ease;
  display: inline-block;
}

.footer-links-column ul li a:hover {
  color: white;
  transform: translateX(3px);
}

/* Footer Middle Section */
.footer-middle {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
  margin-bottom: 60px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding: 50px 0;
}

.app-stores h3, .newsletter h3 {
  color: white;
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 20px;
}

.app-buttons {
  display: flex;
  gap: 15px;
}

.app-button {
  display: flex;
  align-items: center;
  gap: 10px;
  background: rgba(255, 255, 255, 0.1);
  padding: 10px 15px;
  border-radius: 8px;
  text-decoration: none;
  color: white;
  transition: all 0.3s ease;
}

.app-button:hover {
  background: rgba(255, 255, 255, 0.2);
}

.app-button i {
  font-size: 1.8rem;
}

.app-button-text {
  display: flex;
  flex-direction: column;
}

.app-button-text span {
  font-size: 0.75rem;
  opacity: 0.8;
}

.app-button-text strong {
  font-size: 1rem;
  font-weight: 600;
}

.newsletter p {
  font-size: 0.95rem;
  margin-bottom: 20px;
  opacity: 0.8;
}

.newsletter-form {
  display: flex;
}

.newsletter-input {
  flex: 1;
  padding: 12px 15px;
  border: none;
  border-radius: 4px 0 0 4px;
  font-size: 0.95rem;
  background: rgba(255, 255, 255, 0.1);
  color: white;
}

.newsletter-input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.newsletter-input:focus {
  outline: none;
  background: rgba(255, 255, 255, 0.15);
}

.newsletter-button {
  padding: 12px 20px;
  background: linear-gradient(45deg, #6e3afc, #5068f5);
  border: none;
  border-radius: 0 4px 4px 0;
  color: white;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.newsletter-button:hover {
  background: linear-gradient(45deg, #7d4dfb, #6177f3);
}

/* Footer Bottom Section */
.footer-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.copyright {
  font-size: 0.9rem;
  opacity: 0.7;
}

.copyright p {
  margin: 5px 0;
}

.language-button {
  display: flex;
  align-items: center;
  gap: 8px;
  background: rgba(255, 255, 255, 0.1);
  padding: 8px 15px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.language-button:hover {
  background: rgba(255, 255, 255, 0.2);
}

.language-button span {
  font-size: 0.9rem;
}

/* Media Queries */
@media (max-width: 1200px) {
  .footer-top {
    grid-template-columns: 1fr 2fr;
  }
}

@media (max-width: 992px) {
  .footer-top {
    grid-template-columns: 1fr;
    gap: 40px;
  }
  
  .footer-logo {
    align-items: center;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .footer-links {
    grid-template-columns: repeat(2, 1fr);
    gap: 40px 20px;
  }
  
  .footer-middle {
    grid-template-columns: 1fr;
    gap: 40px;
  }
}

@media (max-width: 768px) {
  .site-footer {
    padding: 60px 0 30px;
  }
  
  .footer-bottom {
    flex-direction: column;
    gap: 20px;
    text-align: center;
  }
}

@media (max-width: 576px) {
  .footer-links {
    grid-template-columns: 1fr;
    gap: 30px;
  }
  
  .app-buttons {
    flex-direction: column;
  }
  
  .newsletter-form {
    flex-direction: column;
  }
  
  .newsletter-input {
    border-radius: 4px;
    margin-bottom: 10px;
  }
  
  .newsletter-button {
    border-radius: 4px;
    width: 100%;
  }
} 